import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import dummyImage from "../../images/no-photo-or-blank-image-icon-loading-images-vector-37375020.jpg";
import { saveAs } from "file-saver";
export default function ViewImage({ show, image, onHide }) {
  console.log(image, "image in modal");
  // const downloadImage = () => {
  //   // If the image is a URL, fetch it first
  //   if (typeof image === "string") {
  //     fetch(image)
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error(`Failed to fetch image: ${response.statusText}`);
  //         }
  //         return response.blob();
  //       })
  //       .then((blob) => {
  //         saveAs(blob, "image.jpg");
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //         // Handle the error, e.g., show a message to the user
  //       });
  //   } else if (image instanceof Blob) {
  //     // If the image is already a Blob, directly save it
  //     saveAs(image, "image.jpg");
  //   }
  //   // You can handle other cases based on your specific requirements
  // };
  const downloadImage = () => {
    saveAs(image, 'image.jpg') // Put your image URL here.
  }
  // const downloadImage = async () => {
  //   console.log("download start");
  //   const downloadResult = await fetch(image);
  //   const blob = await downloadResult.blob();
  //   saveAs(blob, "downloaded.jpg");
  //   console.log("download finished");

  //   console.log("onClick start");
  //   const fetchResult = await fetch(image);
  //   const json = await fetchResult.json();
  //   console.log("onClick finished: ", JSON.stringify(json));
  // };
  useEffect(() => {
    if (show) {
    }
  }, [show]);
  return (
    <Modal className="modal fade" show={show} centered>
      <div className="w-100">
        {/* <img src={image?image:dummyImage}  style={{width:"inherit"}}/> */}
        <img
          src={image ? image : dummyImage}
          style={{ width: "inherit" }}
          alt="image"
          // crossOrigin="anonymous"
        />

        <div className="modal-footer">
          <button
            type=""
            className="btn btn-info"
            // disabled={!image}
            onClick={downloadImage}
          >
            Download
          </button>
          <button
            type="button"
            onClick={() => onHide()}
            className="btn btn-danger"
          >
            {" "}
            <i className="flaticon-delete-1"></i> Close
          </button>
        </div>
      </div>
    </Modal>
  );
}
