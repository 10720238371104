import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import { editArticle } from "../../services/ArticleService/ArticleService";
import Spinner from "../common/Spinner";

import addPhoto from "../pages/test";
import PageTitle from "../layouts/PageTitle";
import { Card } from "react-bootstrap";
window.Buffer = window.Buffer || require("buffer").Buffer;
export default function EditArticleDetails(props) {
  const previousData = props.location.state;
  console.log(previousData, "old data");
  const [loader, setLoader] = useState(false);
  const [type, setType] = useState("");
  const [image, setImage] = useState("");
  const [oldImage, setOldImage] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [tag, setTag] = useState("");
  const [description, setDescription] = useState("");
  const [apiError, setApiError] = useState("");
  const albumName = "user";
  let errorsObj = {
    image: "",
    title: "",
    description: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const notifyTopRight = () => {
    toast.success(`✅ Created Successfully.`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleChangeContent = (content) => {
    const cleanedContent = content.replace(/<p>(&nbsp;|\s)*<\/p>/gi, "");
    setDescription(cleanedContent);
  };
  async function onSubmit(e) {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (type === "") {
      errorObj.type = "Type is Required !";
      error = true;
    }
    // if (image === "") {
    //   errorObj.image = "Image is Required !";
    //   error = true;
    // }
    if (title === "") {
      errorObj.title = "Title is Required !";
      error = true;
    }

    if (description === "") {
      errorObj.description = "This Field is Required !";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);

    const imageResponse = await addPhoto(image, albumName);
    const newImage = imageResponse ? imageResponse.Location : oldImage;

    editArticle(
      newImage,
      title,
      category,
      description,
      type,
      tag,
      previousData?._id
    )
      .then((response) => {
        console.log(response, "vgvfdfhjvhfvhg");
        setLoader(false);
        notifyTopRight("");
        // setType("");
        // setImage("");
        // setTitle("");
        // setCategory("");
        // setDescription("");
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error?.response?.data?.message);
        console.log(error.response, "error");
        // setApiError(error.response.data.data);
      });
  }
  useEffect(() => {
    setOldImage(previousData?.image);
    setType(previousData?.articleType);
    setTitle(previousData?.title);
    setCategory(previousData?.category);
    setDescription(previousData?.description);
    setTag(previousData?.subCategory);
  }, []);
  return (
    <div className="">
      <PageTitle activeMenu="Edit Article" motherMenu="Article" />
      <Card>
        <Card.Body>
          <div>
            <h4 className="text-black mb-3">Edit Article Details</h4>
            <form>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Article Type</label>
                    <div className="contact-name">
                      <select
                        className="form-control"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      >
                        <option hidden>Select..</option>
                        <option>Workouts</option>
                        <option>Tips</option>
                      </select>
                      {errors.type && (
                        <div className="text-danger fs-12">{errors.type}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Image</label>
                    <div className="contact-name d-flex align-items-center">
                      <img src={oldImage} alt="img" width={60} height={60} />
                      <input
                        type="file"
                        accept="image/jpeg,image/png,image/jpg"
                        className="form-control ml-2"
                        onChange={(e) => setImage(e.target.files[0])}
                        multiple
                        style={{ paddingTop: "14px" }}
                      />
                      {errors.image && (
                        <div className="text-danger fs-12">{errors.image}</div>
                      )}
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Title</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        name="Cust_Id"
                        required="required"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="title"
                      />
                      {errors.title && (
                        <div className="text-danger fs-12">{errors.title}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Category</label>
                    <div className="contact-name">
                      <select
                        className="form-control"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option hidden>Select..</option>
                        <option>Cardio</option>
                        <option>Functional</option>
                        <option>Strength</option>
                        <option>Mobility</option>
                        <option>Warm-Ups</option>
                        <option>Programs</option>
                        <option>Skills</option>
                      </select>
                      {errors.category && (
                        <div className="text-danger fs-12">
                          {errors.category}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Tag</label>

                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        required="required"
                        value={tag}
                        onChange={(e) => setTag(e.target.value)}
                        placeholder="Subcategory"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="text-black font-w500">Description</label>
                    <Editor
                      apiKey="ww3hvtgactwe1bp9yewjbmsw9jqia8ceiewktnygzdckvhfe"
                      init={{
                        height: 200,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image code charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code textcolor wordcount",
                          "textarea",
                          "textcolor",
                          "forecolor backcolor",
                        ],
                        toolbar:
                          "undo redo | formatselect | code |link | image | bold italic backcolor | alignleft aligncenter alignright alignjustify |  \n" +
                          "bullist numlist outdent indent | textcolor | textarea | forecolor backcolor",
                        content_style:
                          "body p {  margin: 0 }.p1 {  margin: 0 }",
                      }}
                      initialValue={previousData?.description}
                      onEditorChange={handleChangeContent}
                      name="prescription"
                    />
                    {errors.description && (
                      <div className="text-danger fs-12">
                        {errors.description}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-info"
                  onClick={onSubmit}
                >
                  Update
                </button>
                <button
                  type="button"
                 
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
              </div>
            </form>
          </div>
        </Card.Body>
      </Card>
      {loader && <Spinner />}
    </div>
  );
}
