import AWS from "aws-sdk";
require('dotenv').config()
const POOL_REGION = process.env.REACT_APP_BUCKET_REGION;
const POOL_ID = process.env.REACT_APP_BUCKET_POOL_ID;
const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;


AWS.config.update({
  region: POOL_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: POOL_ID,
  }),
});
export default async function addPhoto(files, albumName) {

  console.log(files, "file in test files");
  var file = files;
  var fileName = Math.floor(Math.random() * 10000000) + file.name;
  var albumPhotosKey = albumName + "/";
  var photoKey = albumPhotosKey + fileName;

  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: BUCKET_NAME,
      Key: photoKey,
      Body: file,
      ACL: "public-read",
    },
  });

  var promise = await upload.promise();
  promise.imageName = fileName;
  return promise;
}
