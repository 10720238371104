import React, { useEffect, useState } from "react";
import { Card, Dropdown, Modal } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import { postArticle } from "../../services/ArticleService/ArticleService";
import Spinner from "../common/Spinner";
import addPhoto from "../pages/test";
import PageTitle from "../layouts/PageTitle";
window.Buffer = window.Buffer || require("buffer").Buffer;
export default function AddArticleDetail() {
  let responseImage = {};
  const [loader, setLoader] = useState(false);
  const [type, setType] = useState("");
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [tag, setTag] = useState("");
  const [description, setDescription] = useState("");
  const [apiError, setApiError] = useState("");
  const albumName = "user";

  let errorsObj = {
    image: "",
    title: "",
    description: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleChangeContent = (content) => {
    // const cleanedContent = content.replace(/<p>(&nbsp;|\s)*<\/p>/gi, '');
    setDescription(content);
  };

  async function onSubmit(e) {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (type === "") {
      errorObj.type = "type is Required !";
      error = true;
    }
    if (image === "") {
      errorObj.image = "Image is Required !";
      error = true;
    }
    if (title === "") {
      errorObj.title = "Title is Required !";
      error = true;
    }

    if (description === "") {
      errorObj.description = "This Field is Required !";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    if (image) {
      addPhoto(image, albumName)
        .then((res) => {
          console.log(res, "rrrr");
          postArticle(res.Location, title, category, description, type, tag)
            .then((response) => {
              console.log(response, "vgvfdfhjvhfvhg");
              setLoader(false);
              notifyTopRight("Article created successfully.");
              setType("");
              setImage("");
              setTitle("");
              setCategory("");
              setDescription("");
            
            })
            .catch((error) => {
              setLoader(false);
              notifyError(error.response?.data?.message);
              console.log(error.response, "error");
              setApiError(error.response.data.data);
            });
        })
        .catch((error) => {
          console.log(error, "s3 error");
        });
    }
  }


  return (
    <div className="">
      <PageTitle activeMenu="Create Article" motherMenu="Article" />
      <Card>
        <Card.Body>
        <div>
              <h4 className="text-black mb-3">Add Article Details</h4>
          <form>
            <div className="add-contact-box">
              <div className="add-contact-content">
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Article Type</label>
                  <div className="contact-name">
                    <select
                      className="form-control"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option hidden>Select..</option>
                      <option>Workouts</option>
                      <option>Tips</option>
                    </select>
                    {errors.type && (
                      <div className="text-danger fs-12">{errors.type}</div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Image</label>
                  <div className="contact-name">
                    <input
                      type="file"
                      accept="image/jpeg,image/png,image/jpg"
                      className="form-control"
                      onChange={(e) => setImage(e.target.files[0])}
                      multiple
                      style={{ paddingTop: "14px" }}
                    />
                    {errors.image && (
                      <div className="text-danger fs-12">{errors.image}</div>
                    )}
                  </div>
                </div>

                <div className="form-group mb-3">
                  <label className="text-black font-w500">Title</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      name="Cust_Id"
                      required="required"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Enter title"
                    />
                    {errors.title && (
                      <div className="text-danger fs-12">{errors.title}</div>
                    )}
                  </div>
                </div>
                {type === "Workouts" && (
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Category</label>
                    <div className="contact-name">
                      <select
                        className="form-control"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option hidden>Select..</option>
                        <option>Cardio</option>
                        <option>Functional</option>
                        <option>Strength</option>
                        <option>Mobility</option>
                        <option>Warm-Ups</option>
                        <option>Programs</option>
                        <option>Skills</option>
                      </select>
                      {errors.category && (
                        <div className="text-danger fs-12">
                          {errors.category}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="form-group mb-3">
                  <label className="text-black font-w500">Tag</label>

                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      required="required"
                      value={tag}
                      onChange={(e) => setTag(e.target.value)}
                      placeholder="Enter tag"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="text-black font-w500">Description</label>
                  <Editor
                    apiKey="ww3hvtgactwe1bp9yewjbmsw9jqia8ceiewktnygzdckvhfe"
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image code charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code textcolor wordcount",
                        "textarea",
                        "textcolor",
                        "forecolor backcolor",
                      ],
                      toolbar:
                        "undo redo | formatselect | code |link | image | bold italic underline backcolor | alignleft aligncenter alignright alignjustify |  \n" +
                        "bullist numlist outdent indent | textcolor | textarea | forecolor backcolor",
                      content_style: "body p {  margin: 0 }.p1 {  margin: 0 }",
                    }}
                    onEditorChange={handleChangeContent}
                    name="prescription"
                  />

                  {errors.description && (
                    <div className="text-danger fs-12">
                      {errors.description}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary" onClick={onSubmit}>
                Add
              </button>
              <button
                type="button"
                // onClick={() => onHide()}
                className="btn btn-danger"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Discard
              </button>
            </div>
          </form>
          </div>
        </Card.Body>
      </Card>
      {loader && <Spinner />}
    </div>
  );
}
