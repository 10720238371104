import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Dropdown,
  Nav,
  Tab,
  Table,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import {
  actionAdvertise,
  deleteAdvertise,
  getAdvertise,
  putAdvertiseOrder,
} from "../../services/Advertise/AdvertiseService";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Pagination from "../common/Pagination";
import Spinner from "../common/Spinner";
import PageTitle from "../layouts/PageTitle";
import Advertisement from "../modal/Advertisement";
import dummyImage from "../../images/no-photo-or-blank-image-icon-loading-images-vector-37375020.jpg";
import IndexModal from "../modal/IndexModal";
export default function AddsManagement(props) {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const [postModal, setPostModal] = useState(false);
  const [indexModal, setIndexModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [listLength, setListLength] = useState("");
  const [indexData, setIndexData] = useState("");
  const [adType, setAdtype] = useState("custom");
  const [filter, setFilter] = useState("");
  const [orderChanged, setOrderChanged] = useState(false);
  const limit = 10;
  function getTableData() {
    setLoader(true);
    getAdvertise(currentPage, limit, search, adType, filter)
      .then((response) => {
        console.log(response.data,"response")
        setData(response.data.data.Advertisements);
        setIndexData(response.data.data.adsIndex);
        setListLength(response.data.data.countAdvertisements);
        const total = response.data.data.countAdvertisements;
        setLoader(false);
        setPageCount(Math.ceil(total / limit));
        console.log(response.data, " table data ");
      })
      .catch((error) => {
        console.log(error, "helooooooooo");
        setLoader(false);
        if (error?.response?.data?.statusCode === 401) {
          localStorage.removeItem("tokenDetails");
          props.history.push("/");
        }
      });
  }
  function onDelete(id) {
    setLoader(true);
    deleteAdvertise(id)
      .then((response) => {
        getTableData();
        notifyTopRight(response.data.message);
        console.log(response);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        setLoader(false);
        notifyError(error.response.data.data);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("authDetails");
          props.history.push("/");
        }
      });
  }
  function onAction(id) {
    setLoader(true);
    actionAdvertise(id)
      .then((response) => {
        notifyTopRight(response.data.data.updateAds);
        getTableData();
        setLoader(false);

        console.log(response);
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        setLoader(false);
        notifyError(error.response.data.data);
        if (error?.response?.data?.statusCode === 401) {
          localStorage.removeItem("tokenDetails");
          props.history.push("/");
        }
      });
  }

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    // var updatedList = [...property];
    var updatedList = [...data];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    updatedList.map((data, index) => (data.order = index + 1));
    setOrderChanged(true);
    // setProperty(updatedList);
    setData(updatedList);
    console.log(updatedList, "updatedList.......");
  };
  const sortData = async () => {
    setLoader(true);
    var raw = {
      orders: [
        ...data.map((item, i) => {
          return {
            id: item._id,
            order: item.order,
          };
        }),
      ],
    };
    try {
      const response = await putAdvertiseOrder(raw);
      console.log(response, "success");
      setOrderChanged(false);
      getTableData();
      setLoader(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
      if (error.response?.data?.statusCode === 401) {
        localStorage.removeItem("tokenDetails");
        props.history.push("/");
      }
    }
  };
  useEffect(() => {
    getTableData();
  }, [currentPage, adType, filter]);

  useEffect(() => {
    setCurrentPage(0);
    if (currentPage === 0) {
      getTableData();
    }
  }, [search]);
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="d-flex justify-content-between align-items-center">
        <PageTitle activeMenu="Advertisement List" motherMenu="Advertisement" />
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setIndexModal(true)}
        >
          Manage Index
        </button>
      </div>

      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="home">
          <Nav as="ul" className="nav-tabs">
            <Nav.Item as="li">
              <Nav.Link eventKey="home" onClick={() => setAdtype("custom")}>
                Custom Ads
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link eventKey="second" onClick={() => setAdtype("google")}>
                Google Ads
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="pt-4">
            <Tab.Pane eventKey="home">
              <Card>
                <Card.Header className="d-block">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col-6" style={{ flexGrow: 1 }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          className="input-group border bg-white input-group-sm"
                          style={{ borderRadius: "8px" }}
                        >
                          <input
                            style={{
                              paddingBottom: "25px",
                              paddingTop: "25px",
                              borderRadius: "10px",
                              fontSize: "14px",
                            }}
                            type="text"
                            name="table_search"
                            className="form-control float-right border-0"
                            placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={getTableData}
                            >
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <select
                        className="header-dropdown form-control down-arrow"
                        onChange={(e) => setFilter(e.target.value)}
                        required
                      >
                        <option hidden>Filter by Tab/Subtab</option>
                        <option value="">All</option>
                        <option>Cardio</option>
                        <option>Strength</option>
                        <option>Functional</option>
                        <option>Mobility</option>
                        <option>Warm-Ups</option>
                        <option>Programs</option>
                        <option>Skills</option>
                        <option>Schedule</option>
                        <option>History</option>
                        <option>Tips</option>
                        <option>Wishlisting</option>
                      </select>
                    </div>
                    <div className="">
                      <Button
                        className="btn btn-primary py-2"
                        onClick={() =>
                          props.history.push({
                            pathname: "/create-advertise",
                            state: "custom",
                          })
                        }
                      >
                        Add New +
                      </Button>
                    </div>

                    {orderChanged && (
                      <button
                        className="btn btn-secondary py-2 fw-bold"
                        onClick={() => {
                          sortData();
                        }}
                      >
                        Save Order
                      </button>
                    )}
                  </div>
                </Card.Header>
                <Card.Body>
                  <DragDropContext onDragEnd={handleDrop}>
                    <Table>
                      <thead className="text-black">
                        <tr>
                          <th>
                            <strong>IMAGE</strong>
                          </th>

                          <th>
                            <strong>Type</strong>
                          </th>
                          <th>
                            <strong>tab</strong>
                          </th>
                          <th>
                            <strong>subtab</strong>
                          </th>
                          <th>
                            <strong>Link</strong>
                          </th>
                          <th>
                            <strong>STATUS</strong>
                          </th>
                          <th>
                            <strong>ACTION</strong>
                          </th>
                        </tr>
                      </thead>
                      <Droppable droppableId="droppable-1">
                        {(provider) => (
                          <tbody
                            ref={provider.innerRef}
                            {...provider.droppableProps}
                            className=""
                          >
                            {data?.map((item, index) => (
                              <Draggable
                                key={item._id}
                                draggableId={item._id}
                                index={index}
                              >
                                {(provider) => (
                                  <tr
                                    key={index}
                                    {...provider.draggableProps}
                                    ref={provider.innerRef}
                                  >
                                    <td>
                                      {item?.image &&
                                      item.image.endsWith(".mp4") ? (
                                        <video width={120} height={80} controls>
                                          <source
                                            src={item.image}
                                            type="video/mp4"
                                          />
                                          Your browser does not support the
                                          video tag.
                                        </video>
                                      ) : (
                                        <img
                                          src={
                                            item?.image
                                              ? item?.image
                                              : dummyImage
                                          }
                                          width={120}
                                          height={80}
                                          alt="Image"
                                        />
                                      )}
                                    </td>

                                    <td {...provider.dragHandleProps}>
                                      {item?.category}
                                    </td>
                                    <td {...provider.dragHandleProps}>
                                      {item?.where}
                                    </td>
                                    <td {...provider.dragHandleProps}>
                                      {item?.subWhere ? item?.subWhere : "NA"}
                                    </td>
                                    <td {...provider.dragHandleProps}>
                                      {item?.link ? (
                                        <a href={item?.link} target="_blank">
                                          Visit
                                        </a>
                                      ) : (
                                        "--"
                                      )}
                                    </td>
                                    <td {...provider.dragHandleProps}>
                                      {item.isDisable ? (
                                        <Badge variant="danger light">
                                          Disable
                                        </Badge>
                                      ) : (
                                        <Badge variant="success light">
                                          Enable
                                        </Badge>
                                      )}
                                    </td>
                                    <td {...provider.dragHandleProps}>
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant="info light"
                                          className="light sharp btn btn-info i-false"
                                        >
                                          {svg1}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item></Dropdown.Item>
                                          {item.isDisable ? (
                                            <Dropdown.Item
                                              onClick={() => onAction(item._id)}
                                            >
                                              Enable
                                            </Dropdown.Item>
                                          ) : (
                                            <Dropdown.Item
                                              onClick={() => onAction(item._id)}
                                            >
                                              Disable
                                            </Dropdown.Item>
                                          )}

                                          <Dropdown.Item
                                            onClick={() => onDelete(item._id)}
                                            // onClick={() => onDelete(item)}
                                          >
                                            Delete
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {provider.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </Table>
                  </DragDropContext>
                  {data?.length === 0 && !loader ? (
                    <div className="justify-content-center d-flex my-5 ">
                      Sorry, Data Not Found!
                    </div>
                  ) : (
                    ""
                  )}
                  {data?.length === 0 ? (
                    ""
                  ) : (
                    <div className="card-footer clearfix">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="dataTables_info ">
                          Showing {currentPage * limit + 1} to{" "}
                          {listLength > (currentPage + 1) * limit
                            ? (currentPage + 1) * limit
                            : listLength}{" "}
                          of {listLength} entries
                        </div>
                        <Pagination
                          pageCount={pageCount}
                          pageValue={currentPage}
                          setPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
          <Tab.Content>
            <Tab.Pane eventKey="second">
              <Card>
                <Card.Header className="d-block">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col-6" style={{ flexGrow: 1 }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          className="input-group border bg-white input-group-sm"
                          style={{ borderRadius: "8px" }}
                        >
                          <input
                            style={{
                              paddingBottom: "25px",
                              paddingTop: "25px",
                              borderRadius: "10px",
                              fontSize: "14px",
                            }}
                            type="text"
                            name="table_search"
                            className="form-control float-right border-0"
                            placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={getTableData}
                            >
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <select
                        className="header-dropdown form-control down-arrow"
                        onChange={(e) => setFilter(e.target.value)}
                        required
                      >
                        <option hidden>Filter by Tab/Subtab</option>
                        <option value="">All</option>
                        <option>Cardio</option>
                        <option>Strength</option>
                        <option>Functional</option>
                        <option>Mobility</option>
                        <option>Warm-Ups</option>
                        <option>Programs</option>
                        <option>Skills</option>
                        <option>Schedule</option>
                        <option>History</option>
                        <option>Tips</option>
                        <option>Wishlisting</option>
                      </select>
                    </div>
                    <div className="">
                      <Button
                        className="btn btn-primary py-2"
                        onClick={() =>
                          props.history.push({
                            pathname: "/create-advertise",
                            state: "google",
                          })
                        }
                      >
                        Add New +
                      </Button>
                    </div>
                    {orderChanged && (
                      <button
                        className="btn btn-secondary py-2 fw-bold"
                        onClick={() => {
                          sortData();
                        }}
                      >
                        Save Order
                      </button>
                    )}
                  </div>
                </Card.Header>
                <Card.Body>
                  <DragDropContext onDragEnd={handleDrop}>
                    <Table>
                      <thead className="text-black">
                        <tr>
                          <th>
                            <strong>Type</strong>
                          </th>
                          <th>
                            <strong>tab</strong>
                          </th>
                          <th>
                            <strong>subtab</strong>
                          </th>
                          <th>
                            <strong>STATUS</strong>
                          </th>
                          <th>
                            <strong>ACTION</strong>
                          </th>
                        </tr>
                      </thead>
                      <Droppable droppableId="droppable-1">
                        {(provider) => (
                          <tbody
                            ref={provider.innerRef}
                            {...provider.droppableProps}
                            className=""
                          >
                            {data.map((item, index) => (
                              <Draggable
                                key={item._id}
                                draggableId={item._id}
                                index={index}
                              >
                                {(provider) => (
                                  <tr
                                    key={index}
                                    {...provider.draggableProps}
                                    ref={provider.innerRef}
                                  >
                                    <td {...provider.dragHandleProps}>
                                      {item?.category}
                                    </td>
                                    <td {...provider.dragHandleProps}>
                                      {item?.where}
                                    </td>
                                    <td {...provider.dragHandleProps}>
                                      {item?.subWhere ? item?.subWhere : "NA"}
                                    </td>

                                    <td {...provider.dragHandleProps}>
                                      {item.isDisable ? (
                                        <Badge variant="danger light">
                                          Disable
                                        </Badge>
                                      ) : (
                                        <Badge variant="success light">
                                          Enable
                                        </Badge>
                                      )}
                                    </td>
                                    <td {...provider.dragHandleProps}>
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant="info light"
                                          className="light sharp btn btn-info i-false"
                                        >
                                          {svg1}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item></Dropdown.Item>
                                          {item.isDisable ? (
                                            <Dropdown.Item
                                              onClick={() => onAction(item._id)}
                                            >
                                              Enable
                                            </Dropdown.Item>
                                          ) : (
                                            <Dropdown.Item
                                              onClick={() => onAction(item._id)}
                                            >
                                              Disable
                                            </Dropdown.Item>
                                          )}

                                          <Dropdown.Item
                                            onClick={() => onDelete(item._id)}
                                            // onClick={() => onDelete(item)}
                                          >
                                            Delete
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {provider.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </Table>
                  </DragDropContext>
                  {data?.length === 0 && !loader ? (
                    <div className="justify-content-center d-flex my-5 ">
                      Sorry, Data Not Found!
                    </div>
                  ) : (
                    ""
                  )}
                  {data?.length === 0 ? (
                    ""
                  ) : (
                    <div className="card-footer clearfix">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="dataTables_info ">
                          Showing {currentPage * limit + 1} to{" "}
                          {listLength > (currentPage + 1) * limit
                            ? (currentPage + 1) * limit
                            : listLength}{" "}
                          of {listLength} entries
                        </div>
                        <Pagination
                          pageCount={pageCount}
                          pageValue={currentPage}
                          setPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      {postModal && (
        <Advertisement
          show={postModal}
          table={getTableData}
          onHide={() => setPostModal(false)}
        />
      )}
      {indexModal && (
        <IndexModal
          show={indexModal}
          indexData={indexData}
          table={getTableData}
          onHide={() => setIndexModal(false)}
        />
      )}
      {loader && <Spinner />}
    </div>
  );
}
