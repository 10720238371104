import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { toast } from "react-toastify";
import { uploadFile } from "react-s3";
import { postAdvertise } from "../../services/Advertise/AdvertiseService";
import Spinner from "../common/Spinner";
import { config } from "../../services/AuthService";
import addPhoto from "./test";

export default function AddAdvertise(props) {
  console.log(props, "propsssss");
  let responseImage = "";
  const albumName = "user";
  let errorsObj = {
    tab: "",
    type: "",
    image: "",
    link: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [loader, setLoader] = useState(false);
  const [adType, setAdType] = useState(props.location.state);
  const [tab, setTab] = useState("");
  const [subTab, setSubTab] = useState("");
  const [type, setType] = useState("");
  const [adsBelowCalendar, setAdsBelowCalendar] = useState("");
  const [image, setImage] = useState("");
  const [link, setLink] = useState("");
  const [apiError, setApiError] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const notifyTopRight = () => {
    toast.success(`✅ Created Successfully.`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setLink(inputValue);

    // Regular expression pattern for a URL (you can modify this pattern)
    const urlPattern =
      /^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

    if (urlPattern.test(inputValue)) {
      setValidationMessage("");
    } else {
      setValidationMessage("Invalid URL");
    }
  };
  const handleRadioChange = (e) => {
    setAdsBelowCalendar(e.target.value);
  };
  async function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (tab === "") {
      errorObj.tab = "This Field is Required !";
      error = true;
    }
    if (type === "") {
      errorObj.type = "This Field is Required !";
      error = true;
    }
    if (adType === "custom") {
      if (image === "") {
        errorObj.image = "This Field is Required !";
        error = true;
      }
      if (link === "") {
        errorObj.link = "This Field is Required !";
        error = true;
      }
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    if (image) {
      addPhoto(image, albumName)
        .then((res) => {
          console.log(res, "rrrr");

          postAdvertise(
            adType,
            tab,
            type,
            // index,
            res?.Location,
            link,
            subTab,
            adsBelowCalendar
          )
            .then((response) => {
              console.log(response, "post response");
              notifyTopRight("");
              setTab("");
              setType("");
              setSubTab("");
              setImage("");
              setLink("");
              setLoader(false);
            })
            .catch((error) => {
              setLoader(false);
              notifyError(error?.response?.data?.message);
              console.log(error.response, "error");
            });
        })
        .catch((error) => {
          console.log(error, "s3 error");
        });
    } else {
      postAdvertise(
        adType,
        tab,
        type,
        // index,
        responseImage,
        link,
        subTab,
        adsBelowCalendar
      )
        .then((response) => {
          console.log(response, "post response");
          notifyTopRight("");
          setTab("");
          setType("");
          setSubTab("");

          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          notifyError(error?.response?.data?.message);
          console.log(error.response, "error");
        });
    }
  }

  useEffect(() => {}, [adType]);
  return (
    <div>
      <PageTitle activeMenu="Create Advertise" motherMenu="Advertisement" />
      {/* <Card>
        <Card.Body>
          <div className="text-center">
            <h4 className="text-black">
              What type of advertisement do you want to create?
            </h4>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <button
                className="btn btn-primary"
                onClick={() => setAdType("google")}
              >
                Google Ads
              </button>
              <p className="px-4">OR</p>
              <button
                className="btn btn-primary"
                onClick={() => setAdType("custom")}
              >
                Custom Ads
              </button>
            </div>
          </div>
        </Card.Body>
      </Card> */}
      {adType === "custom" && (
        <Card>
          <Card.Body>
            <div>
              <h4 className="text-black mb-3">Custom Advertisement</h4>
              <form onSubmit={onSubmit}>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Tab</strong>
                  </label>
                  <div className="contact-name">
                    <select
                      className="form-control"
                      onChange={(e) => setTab(e.target.value)}
                      required
                      value={tab}
                    >
                      <option hidden>Select..</option>
                      <option>Workouts</option>
                      <option>Schedule</option>
                      <option>History</option>
                      <option>Tips</option>
                      <option>Wishlisting</option>
                    </select>
                  </div>
                  {errors.tab && (
                    <div className="text-danger fs-12">{errors.tab}</div>
                  )}
                </div>
                {tab === "Workouts" && (
                  <div className="form-group">
                    <label className="mb-2 ">
                      <strong className="">SubTab</strong>
                    </label>
                    <div className="contact-name">
                      <select
                        className="form-control"
                        onChange={(e) => setSubTab(e.target.value)}
                        required
                      >
                        <option hidden>Select..</option>
                        <option>Cardio</option>
                        <option>Strength</option>
                        <option>Functional</option>
                        <option>Mobility</option>
                        <option>Warm-Ups</option>
                        <option>Programs</option>
                        <option>Skills</option>
                      </select>
                    </div>
                    {/* {errors.password && (
                    <div className="text-danger fs-12">{errors.password}</div>
                  )} */}
                  </div>
                )}

                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Type</strong>
                  </label>
                  <div className="contact-name">
                    <select
                      className="form-control"
                      onChange={(e) => setType(e.target.value)}
                      required
                      value={type}
                    >
                      {tab === "Schedule" ||
                      tab === "Wishlisting" ||
                      tab === "History" ? (
                        <>
                          <option hidden>Select..</option>
                          <option value={"banner"}>Banner </option>
                        </>
                      ) : (
                        <>
                          <option hidden>Select..</option>
                          <option value={"banner"}>Banner </option>
                          <option value={"interstitial"}>Interstitial</option>
                        </>
                      )}

                      {/* <option value={"stripe"}>Stripe</option> */}
                    </select>
                  </div>
                  {errors.type && (
                    <div className="text-danger fs-12">{errors.type}</div>
                  )}
                </div>
                {tab === "Schedule" && (
                  <div className="form-group">
                    <label className="mb-2 ">
                      <strong className="">
                        Do you want to start the Ads just below the calendar?
                      </strong>
                    </label>
                    <div className="contact-name">
                      <input
                        type="radio"
                        id="yes"
                        name="adsBelowCalendar"
                        value="yes"
                        checked={adsBelowCalendar === "yes"}
                        onChange={handleRadioChange}
                      />
                      <label className="pl-2" htmlFor="yes">
                        Yes
                      </label>
                      <br />

                      <input
                        type="radio"
                        id="no"
                        name="adsBelowCalendar"
                        value="no"
                        checked={adsBelowCalendar === "no"}
                        onChange={handleRadioChange}
                      />
                      <label className="pl-2" htmlFor="no">
                        No
                      </label>
                    </div>
                    {/* {errors.password && (
                    <div className="text-danger fs-12">{errors.password}</div>
                  )} */}
                  </div>
                )}
                <div className="form-group mb-3">
                  <label className="mb-2">
                    <strong className="">Image/Video </strong>
                  </label>
                  <div className="contact-name">
                    <input
                      type="file"
                      // accept="image/jpeg,image/png,image/jpg"
                      accept="image/jpeg, image/png, image/jpg, video/mp4, video/quicktime"
                      className="form-control"
                      onChange={(e) => setImage(e.target.files[0])}
                      style={{ paddingTop: "14px" }}
                    />
                    {errors.image && (
                      <div className="text-danger fs-12">{errors.image}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Link</strong>
                  </label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      value={link}
                      onChange={(e) => handleInputChange(e)}
                      placeholder="Enter link"
                    />
                  </div>
                  <span className="validation-text text-danger fs-12">
                    {validationMessage}
                  </span>
                  {errors.link && (
                    <div className="text-danger fs-12">{errors.link}</div>
                  )}
                </div>

                <div className="text-center mt-5">
                  <button type="submit" className="btn btn-primary btn-block">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </Card.Body>
        </Card>
      )}
      {adType === "google" && (
        <Card>
          <Card.Body>
            <div>
              <h4 className="text-black mb-3">Google Advertisement</h4>
              <form onSubmit={onSubmit}>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Tab</strong>
                  </label>
                  <div className="contact-name">
                    <select
                      className="form-control"
                      onChange={(e) => setTab(e.target.value)}
                      required
                      value={tab}
                    >
                      <option hidden>Select..</option>
                      <option>Workouts</option>
                      <option>Schedule</option>
                      <option>History</option>
                      <option>Tips</option>
                      <option>Wishlisting</option>
                    </select>
                  </div>
                  {errors.tab && (
                    <div className="text-danger fs-12">{errors.tab}</div>
                  )}
                </div>
                {tab === "Workouts" && (
                  <div className="form-group">
                    <label className="mb-2 ">
                      <strong className="">SubTab</strong>
                    </label>
                    <div className="contact-name">
                      <select
                        className="form-control"
                        onChange={(e) => setSubTab(e.target.value)}
                        required
                      >
                        <option hidden>Select..</option>
                        <option>Cardio</option>
                        <option>Strength</option>
                        <option>Functional</option>
                        <option>Mobility</option>
                        <option>Warm-Ups</option>
                        <option>Programs</option>
                        <option>Skills</option>
                      </select>
                    </div>
                    {/* {errors.password && (
                    <div className="text-danger fs-12">{errors.password}</div>
                  )} */}
                  </div>
                )}

                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Type</strong>
                  </label>
                  <div className="contact-name">
                    <select
                      className="form-control"
                      onChange={(e) => setType(e.target.value)}
                      required
                      value={type}
                    >
                      {tab === "Schedule" ||
                      tab === "Wishlisting" ||
                      tab === "History" ? (
                        <>
                          <option hidden>Select..</option>
                          <option value={"banner"}>Banner </option>
                        </>
                      ) : (
                        <>
                          <option hidden>Select..</option>
                          <option value={"banner"}>Banner </option>
                          <option value={"interstitial"}>Interstitial</option>
                        </>
                      )}

                      {/* <option value={"stripe"}>Stripe</option> */}
                    </select>
                  </div>
                  {errors.type && (
                    <div className="text-danger fs-12">{errors.type}</div>
                  )}
                </div>
                {tab === "Schedule" && (
                  <div className="form-group">
                    <label className="mb-2 ">
                      <strong className="">
                        Do you want to start the Ads just below the calendar?
                      </strong>
                    </label>
                    <div className="contact-name">
                      <input
                        type="radio"
                        id="yes"
                        name="adsBelowCalendar"
                        value="yes"
                        checked={adsBelowCalendar === "yes"}
                        onChange={handleRadioChange}
                      />
                      <label className="pl-2" htmlFor="yes">
                        Yes
                      </label>
                      <br />

                      <input
                        type="radio"
                        id="no"
                        name="adsBelowCalendar"
                        value="no"
                        checked={adsBelowCalendar === "no"}
                        onChange={handleRadioChange}
                      />
                      <label className="pl-2" htmlFor="no">
                        No
                      </label>
                    </div>
                    {/* {errors.password && (
                    <div className="text-danger fs-12">{errors.password}</div>
                  )} */}
                  </div>
                )}
                {/* {type === "banner" && (
                  <div className="form-group">
                    <label className="mb-2 ">
                      {tab === "Schedule" ||
                      tab === "Wishlisting" ||
                      tab === "History" ? (
                        <strong className="">
                          After how many cards do you need to show the Banner
                          Ad?
                        </strong>
                      ) : (
                        <strong className="">
                          After how many Articles do you need to show the Banner
                          Ad?
                        </strong>
                      )}
                    </label>
                    <div className="contact-name">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter number"
                        onChange={(e) => setIndex(e.target.value)}
                        value={index}
                      />
                    </div>
              
                  </div>
                )} */}
                {/* {type === "interstitial" && (
                  <div className="form-group">
                    <label className="mb-2 ">
                      <strong className="">
                        After clicking on how many Articles do you need to show
                        the Ad?
                      </strong>
                    </label>
                    <div className="contact-name">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter number"
                        onChange={(e) => setIndex(e.target.value)}
                        value={index}
                      />
                    </div>
                 
                  </div>
                )} */}
                <div className="text-center mt-5">
                  <button type="submit" className="btn btn-primary btn-block">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </Card.Body>
        </Card>
      )}
      {loader && <Spinner />}
    </div>
  );
}
