import instance from "../Instance";
import {
  AVERTISE_ACTION,
  DELETE_AVERTISE,
  GET_AVERTISE,
  POST_AVERTISE,
  POST_INDEX,
  PUT_ORDER,
} from "./AdvertiseEndPoints";

export function getAdvertise(currentPage, limit, search, type, filter) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  let where;
  let subWhere;

  // Check if filter is one of the specified values
  if (["Schedule", "History", "Tips", "Wishlisting"].includes(filter)) {
    where = filter;
    subWhere = "";
  } else {
    where = "";
    subWhere = filter;
  }
  // Build the query string with only non-empty parameters
  const queryString =
    `?page=${currentPage}&limit=${limit}&search=${search}&type=${type}` +
    (where ? `&where=${where}` : "") +
    (subWhere ? `&subWhere=${subWhere}` : "");
  return instance.get(GET_AVERTISE + queryString, {
    headers: myHeaders,
  });
}

export function deleteAdvertise(id) {
  const data = localStorage.getItem("tokenDetails");

  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const params = { id };
  console.log(params, "params");
  return instance.delete(DELETE_AVERTISE, {
    params,
    headers: myHeaders,
  });
}
// headers: {
//   Authorization: `Bearer ${data}`,
// },
export function actionAdvertise(id) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = { id };
  return instance.put(AVERTISE_ACTION, postData, {
    headers: myHeaders,
  });
}

export function postAdvertise(
  adType,
  tab,
  type,
  // index,
  responseImage,
  link,
  subTab,
  adsBelowCalendar
) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = {
    type: adType,
    where: tab,
    category: type,
    // index: index,
    image: responseImage,
    link,
  };
  // Conditionally add subWhere if subTab is not empty
  if (subTab) {
    postData.subWhere = subTab;
  }
  if (adsBelowCalendar) {
    postData.belowCalender = adsBelowCalendar;
  }
  return instance.post(POST_AVERTISE, postData, {
    headers: myHeaders,
  });
}
export function postAdvertiseIndex(postData) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  // const postData = {
  //   type: adType,
  // };
  return instance.post(POST_INDEX, postData, {
    headers: myHeaders,
  });
}
export function getAdvertiseIndex(category) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  // const postData = {
  //   type: adType,
  // };
  return instance.get(POST_INDEX + `?category=${category}`, {
    headers: myHeaders,
  });
}
export function putAdvertiseOrder(postData) {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  // const postData = {
  //   type: adType,
  // };
  return instance.put(PUT_ORDER, postData, {
    headers: myHeaders,
  });
}
