export const GET_AVERTISE = "admin/advertisement/getAds";

export const DELETE_AVERTISE = "admin/advertisement/delete";

export const AVERTISE_ACTION = "admin/advertisement/adsDisable";

export const POST_AVERTISE = "admin/advertisement/post";

export const POST_INDEX = "admin/advertisement/index";

export const PUT_ORDER = "admin/advertisement/setOrder";

