import React, { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { uploadFile } from "react-s3";
import { toast } from "react-toastify";
import { postAdvertise } from "../../services/Advertise/AdvertiseService";
import Spinner from "../common/Spinner";
import { MultiSelect } from "../pages/MultiSelect";
import { config } from "../../services/AuthService";
window.Buffer = window.Buffer || require("buffer").Buffer;
export default function Advertisement({ show, table, onHide }) {

  let responseImage = {};
  let errorsObj = {
    image: "",
    couponTitle: "",
    couponCode: "",
    offer: "",
    link: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState("");
  const [couponTitle, setCouponTitle] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [offer, setOffer] = useState("");
  const [where, setWhere] = useState([]);
  const [link, setLink] = useState("");
  const [apiError, setApiError] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const notifyTopRight = () => {
    toast.success(`✅ Created Successfully.`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const serviceOptions = [
    { value: "WishList", label: "WishList" },
    { value: "Workouts", label: "Workouts" },
    { value: "Schedule", label: "Schedule" },
    { value: "History", label: "History" },
    { value: "Tips", label: "Tips" },
  ];
  const whereTo = [...where];
  const whereSelected = whereTo?.map((item, i) => {
    return item?.value;
  });

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setLink(inputValue);

    // Regular expression pattern for a URL (you can modify this pattern)
    const urlPattern =
      /^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

    if (urlPattern.test(inputValue)) {
      setValidationMessage("");
    } else {
      setValidationMessage("Invalid URL");
    }
  };
  async function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (image === "") {
      errorObj.image = "Image is Required !";
      error = true;
    }
    if (couponTitle === "") {
      errorObj.couponTitle = "Title is Required !";
      error = true;
    }
    if (couponCode === "") {
      errorObj.couponCode = "This Field is Required !";
      error = true;
    }
    if (offer === "") {
      errorObj.offer = "This Field is Required !";
      error = true;
    }
    if (link === "") {
      errorObj.link = "This Field is Required !";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    const file = new File([image], new Date().getTime());
    console.log(file, "after file creation");
    if (file.size > 0) {
      responseImage = await uploadFile(file, config);
      console.log(responseImage, "after upload");
    }
    setLoader(true);
    postAdvertise(
      responseImage.location,
      couponTitle,
      couponCode,
      offer,
      whereSelected,
      link
    )
      .then((response) => {
        console.log(response, "post response");
        setLoader(false);
        notifyTopRight("");
        setImage("");
        setCouponTitle("");
        setCouponCode("");
        setOffer("");
        onHide();
        table();
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error?.response?.data?.message);
        console.log(error.response, "error");
      });
  }
  return (
    <Modal className="modal fade" show={show} centered>
      <div className="">
        <form>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Add Advertisement Details</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => onHide()}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <i className="flaticon-cancel-12 close"></i>
            <div className="add-contact-box">
              <div className="add-contact-content">
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Type</label>
                  <div className="contact-name">
                    <select
                      className="form-control"
                      // onChange={(e) => setType(e.target.value)}
                      required
                    >
                      <option hidden>Select..</option>
                      <option>Banner</option>
                      <option>Interstational</option>
                      <option>Type 3</option>
                    </select>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Image/Video</label>
                  <div className="contact-name">
                    <input
                      type="file"
                      accept="image/jpeg,image/png,image/jpg"
                      className="form-control"
                      onChange={(e) => setImage(e.target.files[0])}
                      // multiple
                      style={{ paddingTop: "14px" }}
                    />
                    {errors.image && (
                      <div className="text-danger fs-12">{errors.image}</div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Coupon Title</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      required="required"
                      onChange={(e) => setCouponTitle(e.target.value)}
                      placeholder="title"
                    />
                    {errors.couponTitle && (
                      <div className="text-danger fs-12">
                        {errors.couponTitle}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Coupon Code</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      required="required"
                      onChange={(e) => setCouponCode(e.target.value)}
                      placeholder="code"
                    />
                    {errors.couponCode && (
                      <div className="text-danger fs-12">
                        {errors.couponCode}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label className="text-black font-w500">Offer</label>
                  <input
                    type="text"
                    className="form-control"
                    required="required"
                    onChange={(e) => setOffer(e.target.value)}
                    placeholder="offer"
                  />
                  {errors.offer && (
                    <div className="text-danger fs-12">{errors.offer}</div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Where</label>
                  <div className="contact-name">
                    <MultiSelect
                      className="form-control"
                      options={serviceOptions}
                      value={where}
                      onChange={setWhere}
                      required
                    />
                    {errors.category && (
                      <div className="text-danger fs-12">{errors.category}</div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Link</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      required="required"
                      value={link}
                      onChange={(e) => handleInputChange(e)}
                      placeholder="link"
                    />
                    <span className="validation-text text-danger fs-12">
                      {validationMessage}
                    </span>
                    {errors.link && (
                      <div className="text-danger fs-12">{errors.link}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-info" onClick={onSubmit}>
              Add
            </button>
            <button
              type="button"
              onClick={() => onHide()}
              className="btn btn-danger"
            >
              {" "}
              <i className="flaticon-delete-1"></i> Discard
            </button>
          </div>
        </form>
      </div>
      {loader && <Spinner />}
    </Modal>
  );
}
