import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Nav, Tab } from "react-bootstrap";
import { toast } from "react-toastify";
import Spinner from "../common/Spinner";
import {
  deleteAdvertise,
  getAdvertiseIndex,
  postAdvertiseIndex,
} from "../../services/Advertise/AdvertiseService";

export default function IndexModal({ show, indexData, onHide, table }) {
  const [loader, setLoader] = useState(false);
  const [category, setCategory] = useState("banner");
  const [index, setIndex] = useState({
    Cardio: "",
    Strength: "",
    Functional: "",
    Mobility: "",
    "Warm-Ups": "",
    Programs: "",
    Skills: "",
    Schedule: "",
    History: "",
    Tips: "",
    Wishlisting: "",
  });
  let errorsObj = {
    Cardio: "",
    Strength: "",
    Functional: "",
    Mobility: "",
    "Warm-Ups": "",
    Programs: "",
    Skills: "",
    Schedule: "",
    History: "",
    Tips: "",
    Wishlisting: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const wheres = [
    "Cardio",
    "Strength",
    "Functional",
    "Mobility",
    "Warm-Ups",
    "Programs",
    "Skills",
    "Schedule",
    "History",
    "Tips",
    "Wishlisting",
  ];


  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleChange = (e, where) => {
    const value = e.target.value;
    setIndex({ ...index, [where]: value });
  };

  // const renderFormFields = () => {
  //   return wheres.map((where) => (
  //     <div
  //       key={where}
  //       className="form-group mt-3 mb-3 d-flex justify-content-between align-items-center"
  //     >
  //       <label className="text-black font-w500 fs-16">{where} :</label>
  //       <div className="contact-name">
  //         <input
  //           type="number"
  //           className="form-control"
  //           placeholder="Enter number"
  //           onChange={(e) => handleChange(e, where)}
  //           value={index[where] || ""}
  //           min="0"
  //         />
  //         {errors[where] && (
  //           <div className="text-danger fs-12">{errors[where]}</div>
  //         )}
  //       </div>
  //     </div>
  //   ));
  // };
  const renderFormFields = () => {
    return wheres.map((where) => {
      // Exclude fields for "Schedule", "History", and "Wishlisting" if the category is "interstitial"
      if (
        category === "interstitial" &&
        ["Schedule", "History", "Wishlisting"].includes(where)
      ) {
        return null; // Skip rendering for these fields
      }
  
      return (
        <div
          key={where}
          className="form-group mt-3 mb-3 d-flex justify-content-between align-items-center"
        >
          <label className="text-black font-w500 fs-16">{where} :</label>
          <div className="contact-name">
            <input
              type="number"
              className="form-control"
              placeholder="Enter number"
              onChange={(e) => handleChange(e, where)}
              value={index[where] || ""}
              min="0"
            />
            {errors[where] && (
              <div className="text-danger fs-12">{errors[where]}</div>
            )}
          </div>
        </div>
      );
    });
  };
  
  function fetchIndex() {
    setLoader(true);
    getAdvertiseIndex(category)
      .then((response) => {
        setLoader(false);
        // Process the response and update the index state
        const updatedIndex = {};
        response.data.data.forEach((item) => {
          const key = item.subWhere || item.where;
          if (key) {
            updatedIndex[key] = item.index;
          }
        });

        setIndex(updatedIndex);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }
  async function onSubmit(e) {
    e.preventDefault();
    setLoader(true);

    // Prepare the data structure for the API request
    const requestData = {
      category: category, // or simply "category" depending on your API requirements
      index: wheres
        .filter((where) => index[where] !== "") // Exclude objects with empty values
        .map((where) => {
          if (
            [
              "Cardio",
              "Strength",
              "Functional",
              "Mobility",
              "Warm-Ups",
              "Programs",
              "Skills",
            ].includes(where)
          ) {
            // Set a specific 'where' value for the specified conditions
            return {
              indexNumber: index[where],
              where: "Workouts",
              subWhere: where,
            };
          } else {
            // For other conditions, use the default 'where' value
            return {
              indexNumber: index[where],
              where: where,
            };
          }
        }),
    };
    // console.log(requestData, "data in 33463");
    postAdvertiseIndex(requestData)
      .then((response) => {
        console.log(response, "vgvfdfhjvhfvhg");
        setLoader(false);
        notifyTopRight("Done.");
        onHide();
        table();
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error?.response?.data?.message);
        console.log(error.response, "error");
      });
  }
  useEffect(() => {
    fetchIndex();
  }, [category]);
  return (
    <Modal className="modal fade" show={show} centered>
      <div className="modal-header">
        <h4 className="modal-title fs-20">Manage Ads Index</h4>
        <button type="button" className="btn close" onClick={() => onHide()}>
          <span>×</span>
        </button>
      </div>
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="home">
          <Nav as="ul" className="nav-tabs">
            <Nav.Item as="li">
              <Nav.Link eventKey="home" onClick={() => setCategory("banner")}>
                Banner
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link
                eventKey="second"
                onClick={() => setCategory("interstitial")}
              >
                Interstitial
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="">
            <Tab.Pane eventKey="home">
              <div className="">
                <form>
                  <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <h6>
                      After how many Articles/Cards do you need to show the
                      Banner Ad?
                    </h6>
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        {renderFormFields()}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-info"
                      onClick={onSubmit}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      onClick={() => onHide()}
                      className="btn btn-danger"
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Discard
                    </button>
                  </div>
                </form>
              </div>
            </Tab.Pane>
          </Tab.Content>
          <Tab.Content>
            <Tab.Pane eventKey="second">
              <div className="">
                <form>
                  <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <h6>
                      After clicking on how many Articles do you need to show
                      the Ad?
                    </h6>
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        {renderFormFields()}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-info"
                      onClick={onSubmit}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      onClick={() => onHide()}
                      className="btn btn-danger"
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Discard
                    </button>
                  </div>
                </form>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>

      {loader && <Spinner />}
    </Modal>
  );
}
