/// auth service

export const LOGIN = 'admin/auth/login';

export const CHANGE_PASSWORD = 'admin/auth/changePassword';

export const LOGOUT = 'admin/auth/logout';

export const DASHBOARD = 'admin/auth/dashboard';

export const HOMEPAGE = 'admin/advertisement/homePage';


///support form
export const POST_SUPPORTFORM ='user/contactUs';